.predictionGrid {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  padding: 100px 20px 20px 20px;
}

@media (min-width: 1100px) {
  .predictionGrid {
    grid-template-columns: 0.5fr minmax(350px, 1fr) minmax(350px, 0.8fr);
    grid-template-rows: auto auto;
  }
}

.predictionForm {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  grid-row: 1;
  grid-column: 1;
}

@media (min-width: 1100px) {
  .predictionForm {
    grid-row: 1 / span 2;
  }

  .predictionVisualisation {
    grid-row: 1;
    grid-column: 2;
  }

  .predictionExplanation {
    grid-row: 1;
    grid-column: 3;
  }

  .predictionInformation {
    grid-row: 2;
    grid-column: 2;
  }

  .predictionLogo {
    grid-row: 2;
    grid-column: 3;
  }
}
